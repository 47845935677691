import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

const NewsletterPage = ({ data }) => (
  <div className="privacyPolicyPage">
    <Layout>
      <Seo title="COMMB Newsletter" />
    </Layout>
  </div>
)

export default NewsletterPage